import React from 'react';
import Headline from 'headline';
import Icon from 'icon';
import HeroWrapper from 'shared/hero-wrapper';
import Button from 'button';

import s from './page-thanks.module.scss';

export default function Thanks() {
  return (
    <HeroWrapper img="cxn-hero-thanks" className={s.hero}>
      <div className={s.content}>
        <Icon icon="clap" className={s.icon} />
        <Headline dash center h1 white className={s.title}>
          Thanks!
        </Headline>
        <p>
          Your submission has been sent successfully. One of our team members
          will be in touch soon.
        </p>
        <Button hoverWhite href="/">Back to homepage</Button>
      </div>
    </HeroWrapper>
  );
}
